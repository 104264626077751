import { Employee, GanttChartData } from "../../types/ganttChart";
import { groupBy } from "../../utils/arrayUtils";

export const reorderEmployeesInPlace = (
  data: GanttChartData,
): GanttChartData => {
  if (!data.resourceIds && !data.useColorGroupingsAsTeamSplitter) return data;

  if (data.resourceIds) {
    // Convert sorting string into an object with priorities.
    const priorityMap = data.resourceIds.split(",").reduce(
      (acc: Record<string, number>, ref: string, index: number) => {
        acc[ref] = index;
        return acc;
      },
      {} as Record<string, number>,
    );

    // Function to sort the array.
    const sortByEmployeeRef = (employees: Employee[]) => {
      return [...employees].sort((a, b) => {
        return (
          (priorityMap[a.employeeRef] ?? Infinity) -
          (priorityMap[b.employeeRef] ?? Infinity)
        );
      });
    };

    // If we group by Nexus color code
    if (data.useColorGroupingsAsTeamSplitter) {
      // Group employees by colorGroup, then sort within each group and append the results to a final array.
      data.employees = Array.from(
        groupBy(data.employees, (employee) => employee.colorGroup).entries(),
      ).reduce<Employee[]>((acc, [_, groupedEmployees]) => {
        // Append the sorted group to the accumulator
        return [...acc, ...sortByEmployeeRef(groupedEmployees)];
      }, []);
    } else {
      // Sort the employees by the resourceIds.
      data.employees = sortByEmployeeRef(data.employees);
    }
  }

  return data;
};
