import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { PlanningDate } from "../nexusFilters";
import { Job, WebSocketMessagePayload } from "../../hooks/WebSocketProvider";
import api, { API_PATHS } from "../../service/api";
import { PlanViewState, PlanViewStateEntryStatus, SolutionType } from "./index";
import { latestMessageIsOneOf, messageStreamIncludesType } from "./helpers";
import { createPlanViewStateKey, serializeKey } from "./stateKeyUtils";

const determineNexusStatusFromWebSocketMessages = (
  websocketMessages: WebSocketMessagePayload[],
): PlanViewStateEntryStatus => {
  const hasNexusError = messageStreamIncludesType(
    "NexusLandingProgressDataRetrievalFailedMessage",
    websocketMessages,
  );
  const isLoading = latestMessageIsOneOf(
    [
      "NexusLandingProgressDataRetrievalInitiatedMessage",
      "NexusLandingDataRetrievalAlreadyRunningMessage",
    ],
    websocketMessages,
  );
  const isReady = messageStreamIncludesType(
    "NexusLandingProgressNexusDataReadyMessage",
    websocketMessages,
  );

  if (hasNexusError) return "error";

  if (isLoading) return "loading";

  if (isReady) return "ready";

  return "missing";
};

const determinePondooStatusFromWebSocketMessages = (
  websocketMessages: WebSocketMessagePayload[],
): PlanViewStateEntryStatus => {
  const isLoading = latestMessageIsOneOf(
    [
      "NexusLandingProgressOptimizationInitiated",
      "NexusLandingProgressNexusDataReadyMessage",
    ],
    websocketMessages,
  );
  const isReady = messageStreamIncludesType(
    "NexusLandingProgressOptimizationFinished",
    websocketMessages,
  );
  if (isLoading) return "loading";
  if (isReady) return "ready";

  return "missing";
};

const jobEntityIsOutdated = (
  job?: Job,
  messages?: WebSocketMessagePayload[],
) => {
  if (!job || !messages || messages?.length === 0) return false;

  return job.jobRef !== messages.pop()?.jobId;
};

const determineStatusFromJobAndWebSocketMessages = (
  solutionType: SolutionType,
  data: StateObject,
): PlanViewStateEntryStatus => {
  if (
    solutionType === "NexusSolution" &&
    !!data.planningDate?.nexusIdSolution
  ) {
    // For the Nexus view specifically,
    // If we have a NexusIdSolution, then we can disregard state involving the optimization process
    return "ready";
  }

  const { job, websocketMessages } = data;

  if (!job || jobEntityIsOutdated(job, websocketMessages)) {
    // A retrieval might be underway
    if (!websocketMessages) {
      return "missing";
    }

    if (job?.jobStatus === "Completed") return "ready";

    return solutionType === "NexusSolution"
      ? determineNexusStatusFromWebSocketMessages(websocketMessages)
      : determinePondooStatusFromWebSocketMessages(websocketMessages);
  }

  return {
    Completed: "ready",
    Running: "loading",
    Scheduled: "loading",
    Failed: "error",
  }[job.jobStatus] as PlanViewStateEntryStatus;
};

const getPondooValueData = (
  messages: WebSocketMessagePayload[],
  job?: Job,
): { job?: Job; idScenario?: number } => {
  messages = messages ?? [];
  const returnJob =
    job ??
    (messages.find(
      (x) =>
        x.bridgeMessageType === "NexusLandingProgressOptimizationInitiated",
    )?.data as Job | undefined);
  return { job: returnJob, idScenario: returnJob?.idScenario };
};

// Refactored
type StateObject = {
  job?: Job;
  websocketMessages: WebSocketMessagePayload[];
  planningDate?: PlanningDate;
};
type FullStatePayload = Record<number, Record<string, StateObject>>;

/**
 * Hydrates the state of the plan view (gantt chart) history by combining all the way state can be stored at
 * a particular moment: PlanningDates (historical jobs), webSocketJobs (ongoing jobs), and jobEntities.
 * <br>
 * We use the Job (job entity) to set the timeAgo property on the status bar.
 * @param state
 * @param action
 */
export const hydrateFullPlanState = createAsyncThunk(
  "hydrateFullPlanState",
  async () => {
    const response = await api.get<FullStatePayload>(
      API_PATHS.getFullPlanViewStateV2,
    );
    return response.data;
  },
);

function* enumerateFullStatePayload(
  payload: FullStatePayload,
): Generator<{ date: string; filterId: number; data: StateObject }> {
  for (const [filterId, dateMap] of Object.entries(payload)) {
    for (const [date, stateObject] of Object.entries(dateMap)) {
      yield { date, filterId: parseInt(filterId), data: stateObject };
    }
  }
}

export const hydrateFullPlanStateCases = (
  builder: ActionReducerMapBuilder<PlanViewState>,
) => {
  builder.addCase(hydrateFullPlanState.fulfilled, (state, action) => {
    const newState: PlanViewState = {};
    for (const { date, filterId, data } of enumerateFullStatePayload(
      action.payload,
    )) {
      const nexusKey = createPlanViewStateKey("NexusSolution", filterId, date);
      const pondooKey = createPlanViewStateKey(
        "PondooSolution",
        filterId,
        date,
      );

      newState[serializeKey(nexusKey)] = {
        status: determineStatusFromJobAndWebSocketMessages(
          "NexusSolution",
          data,
        ),
        idScenario: data.job?.idScenario,
        job: data.job,
        idSolution: data.planningDate?.nexusIdSolution,
      };

      const pondooData = getPondooValueData(data.websocketMessages, data.job);

      newState[serializeKey(pondooKey)] = {
        status: determineStatusFromJobAndWebSocketMessages(
          "PondooSolution",
          data,
        ),
        idScenario: pondooData.idScenario,
        job: pondooData.job,
        idSolution: data.planningDate?.pondooIdSolution,
      };
    }
    return newState;
  });
};
