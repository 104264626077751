import modalStyles from "../../../styles/UI/modal.module.css"

const Modal = ({
  children,
  backgroundColor
}: {
  children: React.ReactNode | React.ReactNode[];
  backgroundColor?: string
}) => {
  return (
    <article
      className={modalStyles.modal}
style={{
    backgroundColor: backgroundColor? backgroundColor : 'white'
}}
    >
        {children}
    </article>
  );
};

export default Modal;
