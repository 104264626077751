export const Spinner = ({
  height,
  width,
  fullSize,
}: {
  height?: number;
  width?: number;
  fullSize?: boolean;
}) => (
  <>
    {fullSize ? (
      <div
        className="flex items-center justify-center"
        style={{ width: "100%", height: "100%" }}
      >
        <img
          src={require("../../assets/icons/spinner.gif")}
          alt={"Loading..."}
          style={{ height: height ? height : 150, width: width ? width : 150 }}
        />
      </div>
    ) : (
      <img
        src={require("../../assets/icons/spinner.gif")}
        alt={"Loading..."}
        style={{ height: height ? height : 150, width: width ? width : 150 }}
      />
    )}
  </>
);
