import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Job, WebSocketMessagePayload } from "../../hooks/WebSocketProvider";
import {
  createPlanViewStateKey,
  deserializeKey,
  serializeKey,
} from "./stateKeyUtils";
import { hydrateFullPlanStateCases } from "./actions";
import { decodeJobId } from "./helpers";
import { PlanViewKey } from "./hooks";
import labels from "../../utils/labels";

export const SolutionTypes = {
  NEXUS: "NexusSolution",
  PONDOO: "PondooSolution",
} as const;

export type SolutionType = (typeof SolutionTypes)[keyof typeof SolutionTypes];

export type PlanViewStateEntryStatus =
  | "missing"
  | "loading"
  | "ready"
  | "error";

export type PlanViewStateEntry = {
  status: PlanViewStateEntryStatus;
  idScenario?: number;
  idSolution?: number;
  lastMessage?: WebSocketMessagePayload;
  errorMessage?: string;
  job?: Job;
};

/**
 * This type definition is a way to enforce a kind of type safety on the keys of the state, e.g. any key with a
 * valid PlanViewStateEntry value must be the return type of serializeKey, otherwise it is assigned the `never` type.
 * And since `never` is not assignable to type `PlanViewStateEntry`, those will be invalid keys.
 */
export type PlanViewState = {
  [K in string]: PlanViewStateEntry;
} & {
  [K in string]: K extends ReturnType<typeof serializeKey>
    ? PlanViewStateEntry
    : never;
};

const initialState: PlanViewState = {};

const getKeyForOtherSolutionType = (
  solutionType: SolutionType,
  filter: string,
  date: string,
) => {
  const otherType =
    solutionType === "PondooSolution" ? "NexusSolution" : "PondooSolution";
  return serializeKey(
    createPlanViewStateKey(otherType, parseInt(filter), date),
  );
};

const realTimeUpdateSlice = createSlice({
  name: "real-time-updates",
  initialState: initialState,
  reducers: {
    handleWebsocketMessage: (
      state,
      action: PayloadAction<WebSocketMessagePayload>,
    ) => {
      const message = action.payload;

      const { filter, date } = decodeJobId(message.jobId);

      const solutionType: SolutionType = message.bridgeMessageType
        .toLowerCase()
        .includes("optimization")
        ? "PondooSolution"
        : "NexusSolution";
      const key = serializeKey(
        createPlanViewStateKey(solutionType, parseInt(filter), date),
      );
      const otherKey = getKeyForOtherSolutionType(solutionType, filter, date);

      if (!(key in state)) state[key] = { status: "loading" };

      switch (message.bridgeMessageType) {
        case "NexusLandingProgressDataRetrievalInitiatedMessage":
          state[key].status = "loading";
          break;
        case "NexusLandingDataRetrievalAlreadyRunningMessage":
          state[key].status = "loading";
          break;
        case "NexusLandingProgressNexusDataReadyMessage":
          state[key] = {
            ...state[key],
            status: "ready",
            idScenario: message.data.idScenario,
          };
          break;
        case "NexusLandingProgressDataRetrievalFailedMessage":
          state[key].status = "error";
          state[key].errorMessage = message.data;
          break;
        case "NexusLandingProgressOptimizationInitiated":
          state[key] = {
            ...state[key],
            status: "loading",
            idScenario: message.data.idScenario,
            job: message.data,
          };
          // In this case we also need to update the alternate key's job
          state[otherKey] = { ...state[otherKey], job: message.data };
          break;
        case "NexusLandingProgressOptimizationFailed":
          state[key] = {
            ...state[key],
            status: "error",
            job: message.data as Job,
            errorMessage: labels.pondooStatusError,
          };
          break;
        case "NexusLandingProgressOptimizationAborted":
          state[key] = {
            ...state[key],
            status: "error",
            job: message.data as Job,
            errorMessage: labels.pondooStatusError,
          };
          state[otherKey] = { ...state[otherKey], job: message.data };
          break;
        case "NexusLandingProgressOptimizationFinished":
          state[key] = {
            ...state[key],
            status: "ready",
            idScenario: message.data.idScenario,
            job: message.data as Job,
          };
          state[otherKey] = { ...state[otherKey], job: message.data };
          break;
      }
    },
    resetPondooViewStateByScenarioId: (
      state,
      action: PayloadAction<number>,
    ) => {
      const entry = Object.entries(state).find(
        (kv) =>
          kv[1].idScenario === action.payload &&
          deserializeKey(kv[0]).solutionType === "PondooSolution",
      );
      if (!entry) {
        console.error(
          "Could not find PondooSolution with idScenario: ",
          action.payload,
        );
        return;
      }

      state[entry[0]] = { status: "loading" };
    },
    resetPondooViewState: (state, action: PayloadAction<PlanViewKey>) => {
      state[serializeKey(action.payload)] = { status: "missing" };
    },
    setViewStateError: (
      state,
      action: PayloadAction<{ key: PlanViewKey; errorMessage: string }>,
    ) => {
      const { key, errorMessage } = action.payload;
      const serializedKey = serializeKey(key);
      if (!(serializedKey in state)) return;

      state[serializedKey] = {
        ...state[serializedKey],
        status: "error",
        errorMessage: errorMessage,
      };
    },
  },
  extraReducers: (builder) => {
    hydrateFullPlanStateCases(builder);
  },
});

export const {
  handleWebsocketMessage,
  resetPondooViewStateByScenarioId,
  resetPondooViewState,
  setViewStateError,
} = realTimeUpdateSlice.actions;
export default realTimeUpdateSlice.reducer;
