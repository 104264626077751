import headerStyles from "../../styles/header.module.css";
import HeaderCalendar from "./HeaderCalendar";
import HeaderKPIList from "./HeaderKPIList";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { getActiveNexusFilters } from "../../store/nexusFilters/action";
import { useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/Pondoo_clean_logo.svg";

import labels from "../../utils/labels";
import { setCompareWithNexus } from "../../store/KPICollection";
import Switch from "../UI/input/Switch";
import { usePathCheck } from "../../hooks/usePathCheck";
import SendToNexus from "./SendToNexusButton";
import HeaderFilters from "./HeaderFilter";
import { useSearchParams } from "react-router-dom";
import { useSolutionOverviewStatus } from "../../store/realTimeUpdates/hooks";

const Header = () => {
  const dispatch = useAppDispatch();
  const isKpiPage = usePathCheck();
  const compareWithNexus = useAppSelector(
    (state) => state.kpiCollectionSlice.comepareWithNexus,
  );
  const interceptorIsReady = useAppSelector(
    (state) => state.apiConfigurationState.interceptorIsReady,
  );

  const [searchParams, _] = useSearchParams();
  const date = searchParams.get("date");
  const filter = searchParams.get("filter");

  const overviewState = useSolutionOverviewStatus({
    filterId: (filter && parseInt(filter)) || undefined,
    date: date || undefined,
  });

  /**
   * Initial call to hydrate planningDate objects - gives a "data readiness" overview for each day
   */
  useEffect(() => {
    if (interceptorIsReady) dispatch(getActiveNexusFilters());
  }, [interceptorIsReady, dispatch]);

  const { activeNexusFilters } = useAppSelector(
    (state) => state.nexusFiltersSlice,
  );

  /**
   * Data object to display
   */
  const { selectedGanttChartTab, ganttChartData } = useAppSelector(
    (state) => state.ganttChartSlice,
  );

  return (
    <div className={headerStyles.header}>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-[var(--spacing-lg)]">
          <Logo width={40} height={40} />
          <div className={headerStyles.headerFilterWrapper}>
            {activeNexusFilters && (
              <HeaderFilters options={activeNexusFilters} />
            )}
            <HeaderCalendar />
            <HeaderKPIList />
          </div>
        </div>
        <div className="flex gap-[var(--spacing-lg)]">
          {isKpiPage && ganttChartData && (
            <Switch
              label={labels.compareWithNexus}
              id="compare"
              checked={compareWithNexus}
              onChange={() => dispatch(setCompareWithNexus(!compareWithNexus))}
              labelPlacement="BEFORE"
              width={240}
            />
          )}
          {/*
          Enable send to nexus if
          1) we're looking at the Pondoo Plan, or
          2) we're looking at the KPI page and the optimization has run
          */}
          {((selectedGanttChartTab === "Optimeret" && ganttChartData) ||
            (isKpiPage &&
              overviewState.pondooStatus === "ready" &&
              ganttChartData)) && (
            <SendToNexus
              check={ganttChartData.enableSendPlanToNexus}
              idScenario={
                ganttChartData?.idScenario ? ganttChartData?.idScenario : -1
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
