class comparisonUtil {
  static getRoundedComparison = (minuend: number, subtrahend: number) => {
    return Math.round((minuend - subtrahend) * 10) / 10;
  };

  static getRoundedValue = (value: number) => {
    return Math.round(value * 10) / 10;
  };

  static deepEqual<T extends object>(obj1: T, obj2: T): boolean {
    // First check if they're the exact same reference
    if (obj1 === obj2) return true;

    // Get all keys from both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if they have the same number of keys
    if (keys1.length !== keys2.length) return false;

    // Check each key-value pair
    return keys1.every((key) => {
      const val1 = obj1[key as keyof T];
      const val2 = obj2[key as keyof T];

      // If both values are objects, recurse
      if (
        val1 &&
        val2 &&
        typeof val1 === "object" &&
        typeof val2 === "object"
      ) {
        return this.deepEqual(val1, val2);
      }

      // Otherwise do a strict equality comparison
      return val1 === val2;
    });
  }
}

export default comparisonUtil;
