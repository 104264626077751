import switchstyles from "../../../styles/UI/switch.module.css";
import { ReactElement, useEffect, useRef, useState } from "react";
import { ReactComponent as HeartIcon } from "../../../assets/icons/heart.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/x_black.svg";
import { ReactComponent as SelectedHeartIcon } from "../../../assets/icons/heart_purple.svg";
import { ReactComponent as SelectedXIcon } from "../../../assets/icons/x_red.svg";
import { ReactComponent as EllipseIcon } from "../../../assets/icons/ellipse.svg";
import api, { API_PATHS } from "../../../service/api";
import {
  PreferrenceData,
  PreferrenceOption,
  PreferrenceStatus,
} from "../../../types/ganttChart";
import { useAppDispatch } from "../../../store/hooks";
import { setNotification } from "../../../store/toastNotifications";

const TrippleSwitch = ({
  index,
  classes,
  idEmployee,
  idServiceClient,
  status,
  disabled,
  label,
}: {
  index: number;
  classes?: string;
  status: PreferrenceStatus;
  idEmployee: number;
  idServiceClient: number;
  disabled?: boolean;
  label?: string;
}) => {
  const dispatch = useAppDispatch();
  const parent = useRef(null);
  const [currentStatus, setCurrentStatus] = useState<PreferrenceStatus>();

  useEffect(() => {
    setCurrentStatus(status);
  }, []);

  const updatePreferrence = async (status: PreferrenceStatus) => {
    try {
      const data: PreferrenceData = {
        idEmployee: idEmployee,
        idServiceClient: idServiceClient,
        employeeFavorabilityCode: status,
      };

      const response = await api.post<any>(API_PATHS.postPreferrence, data);
      if (response) setCurrentStatus(status as PreferrenceStatus);
    } catch {
      dispatch(
        setNotification({
          message: "Vi kunne ikke opdaterer preferrencerne.",
          type: "send_preferrences",
        })
      );
    }
  };

  const options: PreferrenceOption[] = [
    {
      status: "NOT_PREFERRED",
      defaultIcon: <XIcon width={16} height={16} />,
      selectedIcon: <SelectedXIcon width={16} height={16} />,
      backgroundColor: "var(--col-dirt-rose-very-light)",
      iconColor: "var(--col-dirt-rose-dark)",
      borderColor: "var(--col-dirt-rose)",
    },
    {
      status: "NEUTRAL",
      defaultIcon: <EllipseIcon width={4} height={4} />,
      selectedIcon: <EllipseIcon width={4} height={4} />,
      backgroundColor: "var(--col-grey-2)",
      iconColor: "var(--col-grey-5)",
      borderColor: "var(--col-grey-3)",
    },
    {
      status: "PREFERRED",
      defaultIcon: <HeartIcon width={16} height={16} />,
      selectedIcon: <SelectedHeartIcon width={16} height={16} />,
      backgroundColor: "var(--col-lavendar-50)",
      iconColor: "var(--col-lavendar-dark)",
      borderColor: "var(--col-lavendar)",
    },
  ];
  return (
    <div className={switchstyles.trippleSwitch}>
      {options.map((option) => (
        <button
          ref={parent}
          style={{
            backgroundColor:
              currentStatus === option.status
                ? option.backgroundColor
                : "transparent",
            border:
              currentStatus === option.status
                ? `1px solid ${option.borderColor}`
                : "transparent",
          }}
          className={`${switchstyles.trippleSwitchButton}`}
          disabled={disabled}
          onClick={() => {
            updatePreferrence(option.status);
          }}
        >
          {option.status === currentStatus
            ? option.selectedIcon
            : option.defaultIcon}
        </button>
      ))}

      <span
        className={`${switchstyles.round} ${disabled && switchstyles.disabled}`}
      ></span>
      {status === "PREFERRED" ? (
        <span
          className={`${switchstyles.sliderCircleIconLine} ${
            disabled && switchstyles.disabled
          }`}
        ></span>
      ) : (
        <span
          className={`${switchstyles.sliderCircleIconCircle} ${
            disabled && switchstyles.disabled
          }`}
        ></span>
      )}
    </div>
  );
};

export default TrippleSwitch;
