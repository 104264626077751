import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  OptimizationStoppedToastMessage,
  WebSocketMessagePayload,
} from "../hooks/WebSocketProvider";
import { decodeJobId } from "../store/realTimeUpdates/helpers";
import { setNotification } from "../store/toastNotifications";
import labels from "../utils/labels";

export class WebsocketReactivityService {
  private readonly dispatch: ThunkDispatch<any, any, any>;

  constructor(dispatch: ThunkDispatch<any, any, any>) {
    this.dispatch = dispatch;
  }

  handleMessage = (message: WebSocketMessagePayload) => {
    switch (message.bridgeMessageType) {
      case "OptimizationStoppedToastMessage":
        this.handleOptimizationStoppedMessage(message);
        break;
    }
  };

  private handleOptimizationStoppedMessage = (
    message: OptimizationStoppedToastMessage,
  ) => {
    const { date } = decodeJobId(message.jobId);
    this.dispatch(
      setNotification({
        type: "optimization_stopped",
        message: labels.stopOptimizationToastMessage(date),
      }),
    );
  };
}
