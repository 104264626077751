import toastStyle from "../../styles/toaster.module.css";
import { ReactComponent as CloseIcon } from "../../assets/icons/close_red.svg";
const ToastNotification = ({
  title,
  children,
  removeError,
}: {
  title: string;
  children: React.ReactNode;
  removeError: () => void;
}) => {
  return (
    <div className={toastStyle.toast}>
      <div className="flex justify-between">
        <h3 className={toastStyle.toastTitle}>{title}</h3>
        <button onClick={() => removeError()}>
          <CloseIcon />
        </button>
      </div>
      {children}
    </div>
  );
};

export default ToastNotification;
