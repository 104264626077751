import {
  Employee,
  GanttTabBarTab,
  TimeRange,
  UnplannedTasksType,
  WorkTask,
} from "../types/ganttChart";
import ganttCHartValues, {
  ganttChartVerticalValues,
} from "../utils/ganttChartValues";

class ganttChartService {
  static getPixelsPerMinute = (numberOfHoursShownOnTimeline: number) => {
    const verticalViewPort = document.getElementById(
      "vertical-ganttChart-svg-wrapper"
    )!.clientHeight;
    const totalMinutes = numberOfHoursShownOnTimeline * 60;
    return verticalViewPort / totalMinutes;
  };

  static timeDifferenceInMinutes(startDate: string, endDate: string) {
    const timespan =
      new Date(endDate).getTime() - new Date(startDate).getTime();
    return timespan / 60000;
  }

  static earliestStartDate: number | undefined;
  static latestEndDate: number | undefined;

  static checkDateAgainstEarliestDate = (taskStartDate: number) => {
    if (!this.earliestStartDate) this.earliestStartDate = taskStartDate;
    if (taskStartDate < this.earliestStartDate) {
      this.earliestStartDate = taskStartDate; // Update earliest start date if found earlier task
    }
  };

  static checkDateAgainstLatestDate = (taskEndDate: number) => {
    if (!this.latestEndDate) this.latestEndDate = taskEndDate;
    if (this.latestEndDate < taskEndDate) {
      this.latestEndDate = taskEndDate; // Update earliest start date if found earlier task
    }
  };

  static getStartTime = (task: WorkTask) => {
    let startTime;
    if (task.start) startTime = task.start;
    else if (task.earliestStart) startTime = task.earliestStart;
    else if (task.preferredEarliestStart)
      startTime = task.preferredEarliestStart;
    return startTime;
  };

  static sortAccordingToStartDate = (
    employees: Employee[],
    unplannedNexusTasks: UnplannedTasksType,
    selectedGanttChartTab: GanttTabBarTab,
    unplannedPondooTasks?: UnplannedTasksType
  ): TimeRange => {
    this.earliestStartDate = undefined;
    this.latestEndDate = undefined;

    employees.forEach((employee) => {
      employee.tasks.workTasks.forEach((task) => {
        this.checkDateAgainstEarliestDate(new Date(task.start).getTime());
        this.checkDateAgainstLatestDate(new Date(task.end).getTime());
      });
    });
    if (selectedGanttChartTab === "Original") {
      unplannedNexusTasks?.workTasks.forEach((task) => {
        this.checkDateAgainstEarliestDate(new Date(task.start).getTime());
        this.checkDateAgainstLatestDate(new Date(task.end).getTime());
      });

      unplannedNexusTasks?.auxiliaryTasks.forEach((task) => {
        this.checkDateAgainstEarliestDate(new Date(task.start).getTime());
        this.checkDateAgainstLatestDate(new Date(task.end).getTime());
      });
    } else {
      unplannedPondooTasks?.workTasks.forEach((task) => {
        this.checkDateAgainstEarliestDate(new Date(task.start).getTime());
        this.checkDateAgainstLatestDate(new Date(task.end).getTime());
      });

      unplannedPondooTasks?.auxiliaryTasks.forEach((task) => {
        this.checkDateAgainstEarliestDate(new Date(task.start).getTime());
        this.checkDateAgainstLatestDate(new Date(task.end).getTime());
      });
    }

    return {
      startDate: this.earliestStartDate ? this.earliestStartDate : 0,
      endDate: this.latestEndDate ? this.latestEndDate : 0,
    };
  };

  // ######################## VERTICAL ########################################
  static getVerticalColumnWidth = (
    svgWrapperWidth: number,
    dataLength: number,
    unplannedColumnWidth: number
  ): number => {
    return (
      (svgWrapperWidth -
        ganttChartVerticalValues.verticalViewHeaderColumn -
        unplannedColumnWidth) /
      dataLength
    );
  };

  static getVerticalSvgWidth = (
    dataLength: number,
    columnWidth: number,
    unplannedColumnWidth: number
  ) => {
    return (
      dataLength * columnWidth +
      ganttChartVerticalValues.verticalViewHeaderColumn +
      unplannedColumnWidth
    );
  };
}

export default ganttChartService;
