export const groupBy = <T, R extends string | number | symbol>(
  data: T[],
  grouper: (input: T) => R,
): Map<R, T[]> => {
  const groups = new Map<R, T[]>();

  for (let item of data) {
    const key = grouper(item);
    if (groups.has(key)) {
      groups.get(key)!.push(item);
      continue;
    }
    groups.set(key, [item]);
  }

  return groups;
};

export const intersect = <T extends string | number>(
  arr1: T[],
  arr2: T[],
): T[] => {
  const obj = {} as { [key in T]: boolean };
  for (const item of arr2) {
    obj[item] = true;
  }
  return arr1.filter((x) => obj[x]);
};
