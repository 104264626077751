import { useAppSelector } from "../hooks";
import { createPlanViewStateKey, serializeKey } from "./stateKeyUtils";
import { RootState } from "../index";
import { useMemo } from "react";
import {
  PlanViewStateEntry,
  PlanViewStateEntryStatus,
  SolutionType,
} from "./index";
import { shallowEqual } from "react-redux";

const DEFAULT_MISSING_STATE: SolutionOverviewSummary = {
  nexusStatus: "missing",
  pondooStatus: "missing",
};

export type PlanViewKey = {
  solutionType?: SolutionType;
  filterId?: number;
  date?: string;
};

export type OverviewKey = {
  filterId?: number;
  date?: string;
};

export type SolutionOverviewSummary = {
  nexusStatus: PlanViewStateEntryStatus;
  pondooStatus: PlanViewStateEntryStatus;
  idScenario?: number;
};

/**
 * Create a strongly typed key to index into the state of available solutions. Given a NexusSolution key, will return
 * an object representing the NexusSolution, and likewise with PondooSolution. This hook is therefore scoped to
 * be used by the actual Gantt chart / Plan view pages.
 * @param keyParams
 */
export const useSolutionPlanView = (keyParams: PlanViewKey) => {
  const LOADING_STATE = useMemo(
    () => ({ status: "loading" }) as PlanViewStateEntry,
    [],
  );
  const MISSING_STATE = useMemo(
    () => ({ status: "missing" }) as PlanViewStateEntry,
    [],
  );
  const selector = useMemo(() => {
    return (state: RootState) => {
      // Before we have loaded data from the backend, it's reasonable to put the view into a loading state
      if (
        !state.realTimeUpdateSlice ||
        Object.keys(state.realTimeUpdateSlice).length === 0
      ) {
        return LOADING_STATE;
      }
      if (!keyParams.solutionType || !keyParams.filterId || !keyParams.date) {
        // If we're missing any key components, return a default state
        return MISSING_STATE as PlanViewStateEntry;
      }

      // If we have all components, create and use the key
      const key = createPlanViewStateKey(
        keyParams.solutionType,
        keyParams.filterId,
        keyParams.date,
      );
      const serializedKey = serializeKey(key);
      return state.realTimeUpdateSlice[serializedKey] || MISSING_STATE;
    };
  }, [keyParams.solutionType, keyParams.filterId, keyParams.date]);

  return useAppSelector(selector, shallowEqual);
};

/**
 * Get the loading status of both solutions for the KPI overview page
 * @param keyParams
 */
export const useSolutionOverviewStatus = (keyParams: OverviewKey) => {
  const selector = useMemo(() => {
    return (state: RootState) => {
      // If we're missing any key components, return a default state
      if (!keyParams.filterId || !keyParams.date) {
        return DEFAULT_MISSING_STATE;
      }

      const nexusKey = createPlanViewStateKey(
        "NexusSolution",
        keyParams.filterId,
        keyParams.date,
      );
      const pondooKey = createPlanViewStateKey(
        "PondooSolution",
        keyParams.filterId,
        keyParams.date,
      );

      return {
        nexusStatus:
          state.realTimeUpdateSlice[serializeKey(nexusKey)]?.status ||
          DEFAULT_MISSING_STATE,
        pondooStatus:
          state.realTimeUpdateSlice[serializeKey(pondooKey)]?.status ||
          DEFAULT_MISSING_STATE,
        idScenario:
          state.realTimeUpdateSlice[serializeKey(nexusKey)]?.idScenario,
      } as SolutionOverviewSummary;
    };
  }, [keyParams.filterId, keyParams.date]);
  return useAppSelector(selector, shallowEqual);
};

export interface CreateViewKeyHookParams {
  selectedGanttChartTab: string;
  filter: string | null;
  date: string | null;
}

export const useCreateViewKey = ({
  selectedGanttChartTab,
  filter,
  date,
}: CreateViewKeyHookParams): PlanViewKey => {
  return useMemo(() => {
    return {
      solutionType:
        selectedGanttChartTab === "Original"
          ? "NexusSolution"
          : "PondooSolution",
      filterId: filter ? parseInt(filter) : undefined,
      date: date || undefined,
    };
  }, [selectedGanttChartTab, filter, date]);
};
