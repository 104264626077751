import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";
import { resetSolutionsOnPlanningDate } from "../nexusFilters";
import { PlanViewKey } from "../realTimeUpdates/hooks";
import { AxiosError } from "axios";
import { resetPondooViewState, setViewStateError } from "../realTimeUpdates";
import labels from "../../utils/labels";
import { GanttChartData } from "../../types/ganttChart";

enum actionTypes {
  initiateGanttChartData = "initiateGanttChartData ",
  initiateOptimization = "initiateOptimization ",
  getGanttChartData = "getGanttChartData ",
  getPondooStatus = "getPondooStatus",
  initiateNexusLandingDataExtraction = "initiateNexusLandingDataExtraction",
  updatePondooStatus = "updatePondooStatus",
}

type InitiateGanttChartDataParams = {
  filterId: number;
  date: string;
};

type GetPondooStatusParams = {
  idActiveNexusFilter: number;
  planningDate: string;
  lastModified: string;
};

export type DataExtractionResponse = {
  status: number;
  idScenario?: number;
  message: string;
  jobId: string;
};

export const initiateGanttChartData = createAsyncThunk(
  actionTypes.initiateGanttChartData,
  async (params: InitiateGanttChartDataParams, thunkAPI) => {
    const data = {
      idActiveNexusFilter: params.filterId,
      planningDate: params.date.split("T")[0],
    };
    const response = await api.post<DataExtractionResponse>(
      API_PATHS.initiateNexusPlan,
      data,
    );
    if (response.status === 200) {
      // Reset IDs on that particular planning date
      thunkAPI.dispatch(resetSolutionsOnPlanningDate(params.date));
    }
    return response.data;
  },
);

export const initiateNexusLandingDataExtraction = createAsyncThunk(
  actionTypes.initiateNexusLandingDataExtraction,
  async (
    params: InitiateGanttChartDataParams & {
      pondooFilterId: number;
      filterName: string;
      resourceIds: string;
    },
  ) => {
    const response = await api.post<any>(API_PATHS.nexusLanding, {
      filterId: params.filterId,
      date: params.date.split("T")[0],
      filterName: params.filterName,
      resourceIds: params.resourceIds,
    });
    return response.data;
  },
);

export const initiateOptimization = createAsyncThunk(
  actionTypes.initiateOptimization,
  async (param: number) => {
    const data = {
      idScenario: param,
    };
    const response = await api.post<any>(API_PATHS.startOptimization, data);
    return response.data;
  },
);

export const stopOptimization = createAsyncThunk(
  actionTypes.initiateOptimization,
  async (param: number) => {
    const data = {
      idScenario: param,
    };
    const response = await api.post<any>(API_PATHS.stopOptimization, data);
    return response.data;
  },
);

export const getGanttChartData = createAsyncThunk(
  actionTypes.getGanttChartData,
  async (params: PlanViewKey, thunkAPI) => {
    const sanitizedDate = params.date!.split("T")[0];
    try {
      const response = await api.get<GanttChartData>(
        `${API_PATHS.getNexusPlan}?idActiveNexusFilter=${params.filterId}&date=${sanitizedDate}&pondooSolution=${params.solutionType === "PondooSolution"}`,
      );
      return response.data;
    } catch (error: AxiosError | unknown) {
      const { response } = error as AxiosError;
      if (response?.status === 404) {
        if (params.solutionType === "PondooSolution") {
          thunkAPI.dispatch(resetPondooViewState(params));
        } else {
          // If we for whatever reason don't have a Nexus Solution, inform the user that something went wrong
          thunkAPI.dispatch(
            setViewStateError({
              key: params,
              errorMessage: labels.pondooNexusLandingError,
            }),
          );
        }
      }
    }
  },
);

export const getPondooStatus = createAsyncThunk(
  actionTypes.getPondooStatus,
  async (params: GetPondooStatusParams) => {
    try {
      const response = await api.get<any>(
        `${API_PATHS.retrievePondooStatus}?idActiveNexusFilter=${params.idActiveNexusFilter}&fullState=true&planningDate=${params.planningDate}`,
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },
);
