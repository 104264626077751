import { useAppDispatch } from "../store/hooks";
import BlackRoundWrapper from "./UI/ColouredRoundWrapper";
import CirclularStatusIcon from "./UI/CirclularStatusIcon";
import labels from "../utils/labels";
import StandardButton from "./UI/buttons/StandardButton";
import podooStatusBarStyles from "../styles/pondooStatusBar.module.css";
import { ReactComponent as StarsIcon } from "../assets/icons/stars_white.svg";
import { ReactComponent as XIcon } from "../assets/icons/x.svg";
import { useEffect, useRef, useState } from "react";
import { PondooJobStatus } from "../types/ganttChart";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {
  calculateTimeAgo,
  getBestTimeDisplay,
  getTotalSeconds,
  TimeAgo,
  useTimeAgo,
} from "../hooks/useTimeAgo";
import {
  PlanViewStateEntry,
  resetPondooViewStateByScenarioId,
} from "../store/realTimeUpdates";
import { useSearchParams } from "react-router-dom";
import {
  PlanViewKey,
  useCreateViewKey,
  useSolutionPlanView,
} from "../store/realTimeUpdates/hooks";
import {
  initiateOptimization,
  stopOptimization,
} from "../store/ganttChart/action";
import IconButtonComponent from "./UI/buttons/IconButton";

type PondooStatusBarObject = {
  title: string;
  status: PondooJobStatus;
  buttonTitle?: string;
};

const missingStatus: PondooStatusBarObject = {
  title: labels.pondooMissing,
  status: "MISSING",
};

const missingWithStuckJobStatus: PondooStatusBarObject = {
  title: labels.pondooMissing,
  status: "MISSING",
  buttonTitle: labels.tryAgain,
};

const runningStatus: PondooStatusBarObject = {
  title: labels.pondooRunning,
  status: "Running",
};

const failedStatus: PondooStatusBarObject = {
  title: labels.pondooStatusError,
  buttonTitle: labels.tryAgain,
  status: "Failed",
};

const abortedStatus: PondooStatusBarObject = {
  title: labels.pondooStatusAborted,
  buttonTitle: labels.tryAgain,
  status: "Aborted",
};

const getCompletedTimeAgoLabel = (timeAgo: TimeAgo): string => {
  const timeDisplay = getBestTimeDisplay(timeAgo);
  switch (timeDisplay.unit) {
    case "days":
      return labels.dayAmount(timeDisplay.value);
    case "hours":
      return labels.hoursAmount(timeDisplay.value);
    case "minutes":
      return labels.minutesAmount(timeDisplay.value);
    case "seconds":
      return labels.secondsAmount(timeDisplay.value);
  }
};

const completedStatus = (timeAgo?: TimeAgo): PondooStatusBarObject => ({
  title: timeAgo
    ? labels.pondooCompleted(getCompletedTimeAgoLabel(timeAgo))
    : labels.pondooStatusCalculating,
  status: "Completed",
  buttonTitle: labels.update,
});

const outdatedStatus = (): PondooStatusBarObject => ({
  title: labels.pondooNotUpdated,
  status: "OUTDATED",
  buttonTitle: labels.update,
});

const determinePondooStatusBarState = (
  viewState: PlanViewStateEntry,
  timeAgo: TimeAgo | null | undefined,
  isOutdated: boolean
): PondooStatusBarObject => {
  const { status } = viewState;

  const isReady = status === "ready";
  const isLoading = status === "loading";
  const isError = status === "error";
  const isMissing = status === "missing";

  if (isMissing) {
    if (!!viewState.job) return missingWithStuckJobStatus;
    return missingStatus;
  }

  if (isLoading) {
    return runningStatus;
  }
  if (isReady) {
    if (isOutdated) {
      return outdatedStatus();
    }

    timeAgo = !!timeAgo
      ? timeAgo
      : calculateTimeAgo(viewState.job?.completedDateTime);
    return completedStatus(timeAgo!);
  }
  if (isError) {
    if (viewState.job?.jobStatus === "Aborted") return abortedStatus;
    return failedStatus;
  }

  // Default case
  return missingStatus;
};

const PondooPlanStatusBar = () => {
  const [searchParams, _] = useSearchParams();
  const date = searchParams.get("date");
  const filter = searchParams.get("filter");

  /**
   * Dynamic display state given the user's selection of filter and date
   */
  const viewKey: PlanViewKey = useCreateViewKey({
    selectedGanttChartTab: "Optimeret",
    filter,
    date,
  });

  const planViewState = useSolutionPlanView(viewKey);
  const dispatch = useAppDispatch();
  const startedTimeAgo = useTimeAgo(planViewState?.job?.createdDateTime);
  const completedTimeAgo = useTimeAgo(planViewState?.job?.completedDateTime);
  const isOutdated = !!planViewState?.job?.outdatedDateTime;
  const [pondooStatusBarObject, setPondooStatusBarObject] =
    useState<PondooStatusBarObject>();

  const getProgressBarValue = (timeAgo: TimeAgo | null): number | undefined => {
    if (!timeAgo) return;

    const totalSeconds = getTotalSeconds(timeAgo);

    const anticipatedFinishTimeMinutes = totalSeconds / 60 < 2 ? 2 : 6;
    const anticipatedFinishTimeSeconds = anticipatedFinishTimeMinutes * 60;

    return Math.min(
      100,
      Math.max(0, (totalSeconds / anticipatedFinishTimeSeconds) * 100)
    );
  };

  useEffect(() => {
    setPondooStatusBarObject(
      determinePondooStatusBarState(planViewState, completedTimeAgo, isOutdated)
    );
  }, [planViewState, completedTimeAgo, startedTimeAgo]);

  // Progress bar update based on job start time
  const [progressBarValue, setProgressBarValue] = useState<number>(0);
  useEffect(() => {
    if (startedTimeAgo && planViewState.status === "loading") {
      setProgressBarValue(getProgressBarValue(startedTimeAgo) ?? 0);
    }
  }, [planViewState, startedTimeAgo]);

  // Functions
  const updatePlan = (idScenario: number) => {
    dispatch(resetPondooViewStateByScenarioId(idScenario));
    dispatch(initiateOptimization(idScenario));
  };

  const buttonAction = () => {
    if (planViewState?.idScenario) {
      if (
        planViewState.status === "ready" ||
        planViewState.status === "error" ||
        planViewState.status === "missing"
      ) {
        updatePlan(planViewState.idScenario);
      }
    }
  };

  const abortOptimization = () => {
    if (planViewState?.idScenario) {
      if (planViewState.status === "loading") {
        dispatch(stopOptimization(planViewState.idScenario));
      }
    }
  };

  //Drawing the statusBar based on hover state
  const [unfoldStatusBar, setUnfoldStatusBar] = useState<boolean>(false);

  const statusTitle = useRef<HTMLDivElement>(null);
  const runningBar = useRef<HTMLDivElement>(null);
  const statusButton = useRef<HTMLDivElement>(null);

  const getStatusTitleWidth = () => {
    const baseWidth = 60 + 20; // width of circle + padding
    const titleWidth = statusTitle.current!.offsetWidth; //width pf title <p>
    const widthOfRunningBar = runningBar.current
      ? runningBar.current.offsetWidth + 10
      : 0;
    const buttonWidth = statusButton.current
      ? statusButton.current.offsetWidth + 63
      : 0; // width of button + padding
    return `${baseWidth + titleWidth + buttonWidth + widthOfRunningBar}px `;
  };
  return (
    <BlackRoundWrapper
      bgColor="var(--col-black)"
      height="60px"
      width={unfoldStatusBar ? getStatusTitleWidth() : "60px"}
      classes={`${podooStatusBarStyles.nexusPlanInfo} ${unfoldStatusBar ? "justify-between" : "justify-start"}`}
      onMouseEnter={() => {
        setUnfoldStatusBar(true);
      }}
      onMouseLeave={() => {
        setUnfoldStatusBar(false);
      }}
    >
      {pondooStatusBarObject && (
        <>
          <div className="flex items-center gap-[10px] justofy-start">
            <span className="flex items-center justify-center w-[30px]">
              <CirclularStatusIcon status={pondooStatusBarObject?.status} />
            </span>
            <p
              className={`${unfoldStatusBar ? "visible" : "invisible"} text-nowrap`}
              ref={statusTitle}
            >
              {pondooStatusBarObject.title}
            </p>
          </div>
          {pondooStatusBarObject.buttonTitle && (
            <StandardButton
              onClick={() => {
                buttonAction();
              }}
              variant="WHITE"
              icon={<StarsIcon />}
            >
              <span ref={statusButton}>
                {pondooStatusBarObject.buttonTitle}
              </span>
            </StandardButton>
          )}
          {(pondooStatusBarObject.status === "Running" ||
            pondooStatusBarObject.status === "NotScheduled" ||
            pondooStatusBarObject.status === "Scheduled") && (
            <div className="flex items-center" ref={runningBar}>
              <Box sx={{ width: "200px" }}>
                <LinearProgress
                  variant={"determinate"}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "30px",
                    height: "8px",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "var(--col-lavendar-dark)",
                    },
                  }}
                  value={!!progressBarValue ? Math.round(progressBarValue) : 0}
                />
              </Box>
              <IconButtonComponent
                id="x"
                icon={<XIcon />}
                onClick={abortOptimization}
              ></IconButtonComponent>
            </div>
          )}
        </>
      )}
    </BlackRoundWrapper>
  );
};

export default PondooPlanStatusBar;
