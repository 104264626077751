import { createSlice } from "@reduxjs/toolkit";
import {
  FullTimelineRange,
  FullTimeRange,
  InitialTimelineRange,
} from "../../types/timeline";
import timelineService from "../../service/timelineService";
import { TimelineData } from "../../utils/dateUtil";

type ZoomAction = {
  payload: number;
};

export type TimelineRangeAction = {
  payload: InitialTimelineRange;
};

export interface TimelineSlice {
  distanceFromTop?: number;
  pixelPerMinute: number;
  pixelsPerHour?: number;
  timelineArrayOfTimeString?: TimelineData[];
  timelineRange?: FullTimelineRange;
  fullWorkDayTimeRange?: FullTimeRange; //Used to come back to initial x´zoom, if needed
}

const initialState: TimelineSlice = {
  pixelPerMinute: 1,
};

const timelineSlice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    setPixelsPerMinute(state, action) {
      state.pixelPerMinute = action.payload;
      state.pixelsPerHour = action.payload * 60;
    },
    setTimelineArrayOfTimeStrings(state, action) {
      state.timelineArrayOfTimeString = action.payload;
    },
    setTimelineRange(state, action: TimelineRangeAction) {
      const hoursInView = timelineService.getHoursInView(
        action.payload.firstVisibleHour,
        action.payload.lastVisibleHour
      );
      state.timelineRange = { ...action.payload, hoursInView: hoursInView };
    },
    // Todo: delete ? we may not need this functon anymore.
    // setTimeRangeForFullWorkDay(state, action) {
    //   state.fullWorkDayTimeRange = action.payload;
    // },
    zoomIn(state, action) {
      const isZoomingInPossible =
        state.timelineRange!.hoursInView - 2 * action.payload >= 2;
      if (isZoomingInPossible) {
        const newFirstVisibleHour =
          state.timelineRange!.firstVisibleHour + action.payload;
        const newHoursInView =
          state.timelineRange!.hoursInView - 2 * action.payload;
        state.timelineRange = {
          ...state.timelineRange!,
          firstVisibleHour: newFirstVisibleHour,
          hoursInView: newHoursInView,
        };
      }
    },
    zoomOut(state, action) {
      // Set initial new first Hour to show.
      let firstHourZoomedOut =
        state.timelineRange!.firstVisibleHour - action.payload;
      // Only allow new value, if bigget than or equal to zero, since we only want to show one full day.
      firstHourZoomedOut = firstHourZoomedOut >= 0 ? firstHourZoomedOut : 0;
      // Define new number of hours to show.
      let newNumberOfHoursInView =
        state.timelineRange!.hoursInView + 2 * action.payload;
      // Only allow zooming out in the upper range, if the last visible hour is below 24, to remain within the scope of the day.
      const isLastHourZoomOutPossible =
        firstHourZoomedOut + newNumberOfHoursInView < 24;
      if (!isLastHourZoomOutPossible) {
        firstHourZoomedOut =
          firstHourZoomedOut > 0
            ? firstHourZoomedOut - action.payload
            : firstHourZoomedOut;
      }
      const newFirstVisibleHour = firstHourZoomedOut;
      const newHoursInView = newNumberOfHoursInView;
      state.timelineRange = {
        ...state.timelineRange!,
        firstVisibleHour: newFirstVisibleHour,
        hoursInView: newHoursInView,
      };
    },
    // Todo: fix min and max on zoom, once flow is decided by designteam.
    zoomInOnVerticalView(state, action: ZoomAction) {
      const minutesFromTop = action.payload / state.pixelPerMinute;
      const newPixelsPerMinute = state.pixelPerMinute * 1.5;
      state.pixelPerMinute = newPixelsPerMinute;
      state.pixelsPerHour = newPixelsPerMinute * 60;
      state.distanceFromTop = minutesFromTop * state.pixelPerMinute;
    },

    zoomOutOnVerticalView(state, action: ZoomAction) {
      const minutesFromTop = action.payload / state.pixelPerMinute;
      // set limit on zoomOut to ensure that first tasks are not hidden behind collapsed KPI bar.
      const newPixelsPerMinute =
        state.pixelPerMinute / 1.5 > 0.66 ? state.pixelPerMinute / 1.5 : 0.66;
      state.pixelPerMinute = newPixelsPerMinute;
      state.pixelsPerHour = newPixelsPerMinute * 60;
      state.distanceFromTop = minutesFromTop * state.pixelPerMinute;
    },

    jump(state, action) {
      state.timelineRange = {
        ...state.timelineRange!,
        firstVisibleHour:
          state.timelineRange!.firstVisibleHour + action.payload,
        hoursInView: state.timelineRange!.hoursInView,
      };
    },
  },
});

export const {
  setTimelineArrayOfTimeStrings,
  setTimelineRange,
  zoomIn,
  zoomOut,
  zoomInOnVerticalView,
  zoomOutOnVerticalView,
  jump,
  setPixelsPerMinute,
} = timelineSlice.actions;

export default timelineSlice.reducer;
