import { Employee } from "../../../types/ganttChart";
import ganttChartStyles from "../../../styles/ganttChart/ganttChart.module.css";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import { useAppSelector } from "../../../store/hooks";
import VerticalTitleTextRowLock from "./VerticalTitleTextRowLock";
import VerticalOverlayForBlockedShift from "./VerticalOverlayForBlockedShift";
import {
  PlanViewKey,
  useCreateViewKey,
  useSolutionPlanView,
} from "../../../store/realTimeUpdates/hooks";
import { useSearchParams } from "react-router-dom";

const VerticalTitleTextRow = ({
  title,
  employees,
  solutionType,
}: {
  title: string;
  employees: Employee[];
  solutionType: "Nexus" | "Pondoo";
}) => {
  const { verticalColumnWidth, unplannedColumnWidth, selectedGanttChartTab } =
    useAppSelector((state) => state.ganttChartSlice);

  const widthWithoutLock =
    verticalColumnWidth - 25 > 0 ? verticalColumnWidth - 25 : 0;
  const [searchParams, _] = useSearchParams();
  const date = searchParams.get("date");
  const filter = searchParams.get("filter");

  /**
   * Dynamic display state given the user's selection of filter and date
   */
  const viewKey: PlanViewKey = useCreateViewKey({
    selectedGanttChartTab,
    filter,
    date,
  });

  const planViewState = useSolutionPlanView(viewKey);

  return (
    <div
      className={ganttChartStyles.verticalColumnTextItemsWrapper}
      style={{
        transition: "ease-in",
        position: "sticky",
        top: 0,
      }}
    >
      <div
        className={ganttChartStyles.columnTitleCell}
        style={{
          height:
            ganttChartVerticalValues.verticalViewTextRowHeight.toString() +
            "px",
        }}
      >
        <p>{title}</p>
      </div>
      <div
        className={ganttChartStyles.verticalTitleTextRow}
        style={{
          opacity: 1,
          width: unplannedColumnWidth + "px",
          minWidth: unplannedColumnWidth + "px",
        }}
        key={`VerticalTitleTextRow-0`}
      >
        <p>Uplanlagte</p>
      </div>
      {employees.map((employee, index) => (
        <div
          className={ganttChartStyles.verticalTitleTextRow}
          style={{
            opacity: 1,
            width: verticalColumnWidth + "px",
            minWidth: verticalColumnWidth + "px",
          }}
          key={`VerticalTitleTextRow-${index + 1}`}
        >
          <VerticalOverlayForBlockedShift employee={employee} />
          <p
            title={employee.fullName}
            style={{ width: widthWithoutLock + "px" }}
          >
            {employee.fullName}
          </p>
          <VerticalTitleTextRowLock
            employee={employee}
            solutionType={solutionType}
            planViewState={planViewState}
          />
        </div>
      ))}
    </div>
  );
};

export default VerticalTitleTextRow;
