import KpiWrapper from "../ganttChart/KpiWrapper";
import ganttChartStyles from "../../styles/ganttChart/ganttChart.module.css";
import colors from "../../utils/colors";
import TinyDivider from "../UI/TinyDivider";
import GreyOpacityText from "../UI/textFields/GreyOpacityText";
import labels from "../../utils/labels";

const KPIListOverview = ({
  continuity,
  distance,
}: {
  continuity: number;
  distance?: number;
}) => {
  return (
    <div className="flex gap-[--spacing-sm] items-center">
      <GreyOpacityText
        classes="flex gap-[--spacing-sm] items-center whitespace-nowrap"
        size="SMALL"
      >
        <span
          className={ganttChartStyles.kpiBullet}
          style={{
            backgroundColor: colors.getBackgroundColor(continuity),
          }}
        ></span>
        {continuity} {labels.affinity}
      </GreyOpacityText>
      <TinyDivider colorVariant="PRIMARY"></TinyDivider>
      <GreyOpacityText
        classes="flex gap-[--spacing-sm] items-center whitespace-nowrap"
        size="SMALL"
      >
        {distance ? distance : "-"} Km
      </GreyOpacityText>
    </div>
  );
};

export default KPIListOverview;
