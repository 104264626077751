import {
  Employee,
  LockShiftScheduleData,
  ShiftSchedule,
} from "../../../types/ganttChart";
import ganttChartStyles from "../../../styles/ganttChart/ganttChart.module.css";
import api, { API_PATHS } from "../../../service/api";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { ReactComponent as LockUnlockedIcon } from "../../../assets/icons/lock-unlocked.svg";
import { updateLockedForPlanning } from "../../../store/ganttChart";
import { setNotification } from "../../../store/toastNotifications";
import { stopOptimization } from "../../../store/ganttChart/action";
import { useAppDispatch } from "../../../store/hooks";
import { PlanViewStateEntry } from "../../../store/realTimeUpdates";
import labels from "../../../utils/labels";
import { useState } from "react";
import Overlay from "../../UI/Overlay";
import Modal from "../../UI/modal/Modal";
import StandardButton from "../../UI/buttons/StandardButton";
import Header2 from "../../UI/textFields/Header2";
import Body2 from "../../UI/textFields/Body2";

const VerticalTitleTextRowLock = ({
  employee,
  solutionType,
  planViewState,
}: {
  employee: Employee;
  solutionType: "Nexus" | "Pondoo";
  planViewState: PlanViewStateEntry;
}) => {
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);

  const isOptimizationRunning = (): boolean => {
    if (!planViewState.job) return false;

    return ["NotScheduled", "Scheduled", "Running"].includes(
      planViewState.job.jobStatus,
    );
  };

  const isNexusSolution = solutionType === "Nexus";

  const toggleLockedForPlanning = async (shiftSchedule: ShiftSchedule) => {
    if (isNexusSolution) {
      const lockedForPlanning = !shiftSchedule.lockedForPlanning;
      const idShift = shiftSchedule.idShift;

      try {
        const data: LockShiftScheduleData = {
          idShift: idShift,
          lockedForPlanning: lockedForPlanning,
        };

        const response = await api.post<any>(
          API_PATHS.postLockShiftSchedule,
          data,
        );
        if (response) {
          if (isOptimizationRunning())
            dispatch(stopOptimization(planViewState.job!.idScenario));

          dispatch(updateLockedForPlanning({ idShift, lockedForPlanning }));
        }
      } catch {
        dispatch(
          setNotification({
            message: labels.ganttChartLockErrorMessage,
            type: "lock_shift",
          }),
        );
      }
    }
  };

  return (
    <>
      {showModal ? (
        <Overlay darkBackground clickAway={() => setShowModal(false)}>
          <Modal>
            <Header2>{labels.ganttChartLockShiftWarningHeader}</Header2>
            <Body2>{labels.ganttChartLockShiftWarningBody}</Body2>
            <div className="flex justify-end gap-[var(--spacing-sm)] w-full">
              {" "}
              <StandardButton
                variant="PRIMARY"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {labels.ganttChartLockShiftCancel}
              </StandardButton>
              <StandardButton
                variant="SECONDARY"
                onClick={async () => {
                  await toggleLockedForPlanning(employee.shiftSchedule);
                }}
              >
                {labels.ganttChartLockShiftProceed}
              </StandardButton>
            </div>
          </Modal>
        </Overlay>
      ) : (
        <span
          className={`${isNexusSolution ? ganttChartStyles.verticalTitleLockIconWithPointer : ""}
			  ${!isNexusSolution && !employee.shiftSchedule.lockedForPlanning ? ganttChartStyles.verticalTitleLockIconHidden : ""}
			  absolute right-1`}
        >
          {employee.shiftSchedule.lockedForPlanning ? (
            <LockIcon
              height={14}
              width={14}
              onClick={async () => {
                if (isOptimizationRunning()) {
                  setShowModal(true);
                } else await toggleLockedForPlanning(employee.shiftSchedule);
              }}
            />
          ) : (
            <LockUnlockedIcon
              height={14}
              width={14}
              onClick={async () => {
                if (isOptimizationRunning()) {
                  setShowModal(true);
                } else await toggleLockedForPlanning(employee.shiftSchedule);
              }}
            />
          )}
        </span>
      )}
    </>
  );
};

export default VerticalTitleTextRowLock;
