import StandardButton from "../UI/buttons/StandardButton";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron_black.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import api, { API_PATHS } from "../../service/api";
import { useAppDispatch } from "../../store/hooks";
import { setNotification } from "../../store/toastNotifications";
import React, { useEffect, useState } from "react";
import labels from "../../utils/labels";
import { Spinner } from "../UI/Spinner";

const SendToNexus = ({
  idScenario,
  check,
}: {
  idScenario: number;
  check: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [endpointResult, setEndpointResult] = useState<{
    hasCalled: boolean;
    success: boolean;
  }>({ hasCalled: false, success: false });
  const [callingBackend, setCallingBackend] = useState<boolean>(false);
  const [icon, setIcon] = useState<React.ReactNode>(<ChevronIcon />);
  const sendToNexus = async () => {
    setCallingBackend(true);
    try {
      const body = {
        idScenario: idScenario,
      };
      const response = await api.post<any>(API_PATHS.postNexusPlan, body);
      if (response.status === 200) {
        setEndpointResult({ hasCalled: true, success: true });
      }
      if (response.status >= 400) {
        setEndpointResult({ hasCalled: true, success: false });
        dispatch(
          setNotification({
            message: "Vi kunne ikke sende planen til Nexus",
            type: "send_to_nexus",
          }),
        );
      }
    } catch {
      setEndpointResult({ hasCalled: true, success: false });
      dispatch(
        setNotification({
          message: "Vi kunne ikke sende planen til Nexus",
          type: "send_to_nexus",
        }),
      );
    }
    setCallingBackend(false);
  };

  useEffect(() => {
    if (endpointResult.hasCalled && endpointResult.success)
      setIcon(<CheckIcon />);
    else if (callingBackend) setIcon(<Spinner height={18} width={18} />);
    else setIcon(<ChevronIcon />);
  }, [check, callingBackend, endpointResult]);

  return (
    <StandardButton
      onClick={() => sendToNexus()}
      variant="SECONDARY"
      icon={icon}
    >
      {labels.sendNexusData}
    </StandardButton>
  );
};

export default SendToNexus;
