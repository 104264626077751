import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToastNotification } from "../../types/errors";

export interface ToastNotifications {
  notifications: ToastNotification[];
}

const initialState: ToastNotifications = {
  notifications: [],
};

const toastNotificationsSlice = createSlice({
  name: "toastNotifications",
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<ToastNotification>) => {
      state.notifications.push(action.payload);
    },
    removeNotification: (state, action: PayloadAction<ToastNotification>) => {
      state.notifications = state.notifications.filter(
        (notification) => action.payload.type !== notification.type,
      );
    },
  },
});

export const { setNotification, removeNotification } =
  toastNotificationsSlice.actions;

export default toastNotificationsSlice.reducer;
