import { Employee } from "../../../../types/ganttChart";
import VerticalTextRow from "../VerticalTextRow";
import ganttChartStyles from "../../../../styles/ganttChart/ganttChart.module.css";
import KpiWrapper from "../../KpiWrapper";
import VerticalGanttChartTransportationIcon from "../VerticalGanttChartTransportationIcon";
import ViolationChip from "../../../UI/ViolationChip";
import React, { useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import VerticalOverlayForBlockedShift from "../VerticalOverlayForBlockedShift";
import labels from "../../../../utils/labels";

const KpiRows = ({ employees }: { employees: Employee[] }) => {
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  const getContinuityArray = () => {
    // if (selectedGanttChartTab === "Nexus") {
    return employees.map((employee, index) =>
      employee.shiftSchedule.kpi.continuity != null ? (
        <>
          <KpiWrapper
            value={Math.round(employee.shiftSchedule.kpi.continuity * 10) / 10}
            showColoredChip
            key={index}
          />
          <VerticalOverlayForBlockedShift employee={employee} />
        </>
      ) : (
        <VerticalOverlayForBlockedShift employee={employee} />
      )
    );
  };

  const getDistanceArray = () => {
    // if (selectedGanttChartTab === "Nexus") {
    return employees.map((employee, index) => (
      <>
        <KpiWrapper
          value={Math.round(
            employee.shiftSchedule.kpi.travelDistanceInMeters / 1000
          )}
          key={index}
        >
          <VerticalGanttChartTransportationIcon
            type={employee.transportationType}
            color="var(--col-black)"
            size={10}
          />
        </KpiWrapper>
        <VerticalOverlayForBlockedShift employee={employee} />
      </>
    ));
  };

  const getViolationsArray = () => {
    // if (selectedGanttChartTab === "Nexus") {
    return employees.map((employee, index) =>
      employee.shiftSchedule.kpi.violations &&
      employee.shiftSchedule.kpi.violations.length > 0 ? (
        <>
          <KpiWrapper
            value={employee.shiftSchedule.kpi.violations.length}
            key={index}
          >
            <ViolationChip height={14} width={14} />
          </KpiWrapper>
          <VerticalOverlayForBlockedShift employee={employee} />
        </>
      ) : (
        <VerticalOverlayForBlockedShift employee={employee} />
      )
    );
  };

  const getCollapsedArray = () =>
    employees.map((employee, idx) => (
      <React.Fragment key={`fragment-${idx}`}>
        {verticalColumnWidth > 90 &&
          employee.shiftSchedule.kpi.violations.length > 0 &&
          employee.shiftSchedule.kpi.continuity != null && (
            <KpiWrapper
              id="continuity"
              value={
                Math.round(employee.shiftSchedule.kpi.continuity * 10) / 10
              }
              showColoredChip
              key={`continuity-colapsed-${idx}`}
            />
          )}

        {verticalColumnWidth > 150 && (
          <KpiWrapper
            id="distance"
            value={Math.round(
              employee.shiftSchedule.kpi.travelDistanceInMeters / 1000
            )}
            key={`transport-${idx}`}
          >
            <VerticalGanttChartTransportationIcon
              type={employee.transportationType}
              color="var(--col-black)"
              size={10}
            />
          </KpiWrapper>
        )}

        {employee.shiftSchedule.kpi.violations &&
        employee.shiftSchedule.kpi.violations.length > 0 ? (
          <KpiWrapper
            id="violations"
            value={employee.shiftSchedule.kpi.violations.length}
            key={`violations-${idx}`}
          >
            <ViolationChip height={14} width={14} />
          </KpiWrapper>
        ) : (
          employee.shiftSchedule.kpi.continuity != null && (
            <KpiWrapper
              id="continuity"
              value={
                Math.round(employee.shiftSchedule.kpi.continuity * 10) / 10
              }
              showColoredChip
              key={`continuity-2-${idx}`}
            />
          )
        )}

        <VerticalOverlayForBlockedShift employee={employee} />
      </React.Fragment>
    ));

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <section
        style={{
          pointerEvents: "auto",
        }}
        className={`${ganttChartStyles.verticalTextRow} ${isHovered ? ganttChartStyles.unfoldMultipleKpi : ganttChartStyles.foldMultipleKpi}`}
        onMouseLeave={(e: any) => {
          e.stopPropagation();
          setIsHovered(false);
        }}
      >
        <>
          <VerticalTextRow
            title={labels.affinity}
            array={getContinuityArray()}
          ></VerticalTextRow>
          <VerticalTextRow
            title="Kørsel (km)"
            array={getDistanceArray()}
          ></VerticalTextRow>
          <VerticalTextRow
            title="Regelbrud"
            array={getViolationsArray()}
          ></VerticalTextRow>
        </>
      </section>
      <section
        className={`${ganttChartStyles.verticalTextRow}  ${isHovered ? ganttChartStyles.foldCollapsedKpi : ganttChartStyles.unfoldCollapsedKpi}`}
        style={{
          pointerEvents: "auto",
        }}
        onMouseEnter={(e: any) => {
          e.stopPropagation();
          setIsHovered(true);
        }}
      >
        <VerticalTextRow title="" array={getCollapsedArray()}></VerticalTextRow>
      </section>
    </>
  );
};

export default KpiRows;
