import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  hideTaskHoverBox,
  openDrawer,
  showTaskHoverBox,
} from "../../../store/drawer";
import { Employee, OperationalTask, WorkTask } from "../../../types/ganttChart";
import taskStyles from "../../../styles/ganttChart/tasks.module.css";
import ViolationChip from "../../UI/ViolationChip";
import { useEffect, useState } from "react";
import ganttChartService from "../../../service/ganttChartService";
import TaskViolationBox from "./TaskViolationBox";
import { HighlightTasks } from "../../../utils/highlightUtil";
import { setHighlightedTasks } from "../../../store/search";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning_yellow.svg";

const VerticalOperationalTask = ({
  xPosition,
  yPosition,
  width,
  task,
  height,
  employee,
}: {
  xPosition: number;
  yPosition: number;
  width: number;
  task: WorkTask | OperationalTask;
  height: number;
  employee?: Employee;
}) => {
  const dispatch = useAppDispatch();
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  const { pixelPerMinute } = useAppSelector((state) => state.timelineSlice);
  const highlightedTaskIds = useAppSelector(
    (state) => state.searchSlice.highlightedTaskIds
  );

  /* Below are all the calculations to draw the red violation boxes, 
  when tasks exceed the employees shift. */
  const [taskExceedsEndOfShift, setTaskExceedsEndOfShift] =
    useState<boolean>(false);
  const [violationHeight, setViolationHeight] = useState<number>();
  const [fullTaskViolation, setFullTaskViolation] = useState<boolean>(false);

  useEffect(() => {
    if (
      employee &&
      new Date(task.end).getTime() > new Date(employee?.shiftEndTime).getTime()
    ) {
      setTaskExceedsEndOfShift(true);
      const violationHeight =
        ganttChartService.timeDifferenceInMinutes(
          employee!.shiftEndTime,
          task.end
        ) * pixelPerMinute;
      setViolationHeight(violationHeight);
      if (violationHeight >= height) {
        setFullTaskViolation(true);
      }
    }
  }, [pixelPerMinute]);

  return (
    <>
      {verticalColumnWidth && height && (
        <>
          <foreignObject
            x={xPosition + 2}
            y={yPosition}
            width={width}
            height={height}
            onMouseEnter={() => {
              dispatch(
                showTaskHoverBox({
                  type: "OPERATIONAL",
                  task: task,
                  employee: employee,
                })
              );
            }}
            onMouseLeave={() => {
              dispatch(hideTaskHoverBox());
            }}
          >
            <div
              onClick={() => {
                if (highlightedTaskIds.length === 0) {
                  dispatch(
                    openDrawer({ task: task, employeeName: employee?.fullName })
                  );
                } else {
                  dispatch(setHighlightedTasks([]));
                }
              }}
              className={taskStyles.verticalAuxiliaryTask}
              style={{
                backgroundColor:
                  task.ganttChartPosition === 0
                    ? "var(--col-mocha-late)"
                    : "var(--col-mocha-cappucino)",
                opacity: HighlightTasks.getOpacity(
                  highlightedTaskIds,
                  task.idTask
                ),
              }}
            >
              <p className={taskStyles.verticalTaskText}>{task.description}</p>
              <div className="flex flex-wrap gap-[var(--spacing-xs)] absolute bottom-[var(--spacing-sm)] right-[var(--spacing-xs)] justify-end">
                {task.violations && task.violations.length > 0 && (
                  <ViolationChip height={14} width={14}></ViolationChip>
                )}
                {((!task.endLocation.latitude && !task.endLocation.longitude) ||
                  (!task.startLocation.latitude &&
                    !task.startLocation.longitude)) && (
                  <WarningIcon height={14} width={14} />
                )}
              </div>
            </div>
          </foreignObject>
          {taskExceedsEndOfShift && violationHeight && (
            <TaskViolationBox
              xPosition={xPosition}
              yPosition={yPosition}
              violationHeight={violationHeight}
              height={height}
              width={width}
              shiftEndTime={employee!.shiftEndTime}
              fullTaskViolation={fullTaskViolation}
            />
          )}
        </>
      )}
    </>
  );
};

export default VerticalOperationalTask;
