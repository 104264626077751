import textStyles from "../../../styles/UI/textComponents.module.css"
const Header2 = ({
  children,
  color
}: {
  children:string
  color?: string
}) => {
  return (
    <h2
      className={textStyles.header2}
style={{
    color: color? color : 'var(--text-primary)'
}}
    >
        {children}
    </h2>
  );
};

export default Header2;
