import { Employee } from "../types/ganttChart";

export type TeamGroupColorMaps = {
  colorGroupCounts: Record<string, number>;
  colorGroupToTeamNumberMap: Map<string, string>;
};

class TeamColorGroupUtils {
  public static computeTeamColorGroupMaps = (
    employees: Employee[],
  ): TeamGroupColorMaps => {
    const colorGroupCounts: Record<string, number> = {};
    const colorGroupToTeamNumberMap = employees.reduce((map, employee) => {
      const { colorGroup } = employee;
      colorGroupCounts[colorGroup] = (colorGroupCounts[colorGroup] ?? 0) + 1;
      if (!map.has(colorGroup)) {
        const teamNumber = map.size + 1;
        map.set(colorGroup, `Team ${teamNumber}`);
      }

      return map;
    }, new Map<string, string>());

    return { colorGroupCounts, colorGroupToTeamNumberMap };
  };
}

export default TeamColorGroupUtils;
