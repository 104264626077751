import textStyles from "../../../styles/UI/textComponents.module.css";

const Body2 = ({ children, color, bold, whiteSpaceNoWrap }: { children: string; color?: string, bold?: boolean, whiteSpaceNoWrap?: boolean }) => {
  return (
    <p className={`${textStyles.body2} ${bold ? "bold" : ""}`}
    style={{
        color: color? color : 'var(--text-primary)',
        whiteSpace: whiteSpaceNoWrap ? 'nowrap' : 'normal'
    }}>{children}</p>
  );
};

export default Body2;
