import timestampService from "../../../service/timestampService";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import ganttChartStyles from "../../../styles/ganttChart/ganttChart.module.css";
import { useEffect } from "react";
import ganttChartService from "../../../service/ganttChartService";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setPixelsPerMinute } from "../../../store/timeline";

const VerticalGanttChartTimelineColumn = () => {
  const dispatch = useAppDispatch();
  const { verticalSvgWidth } = useAppSelector((state) => state.ganttChartSlice);
  const {
    timelineArrayOfTimeString,
    pixelPerMinute,
    timelineRange,
    pixelsPerHour,
  } = useAppSelector((state) => state.timelineSlice);
  useEffect(() => {
    if (timelineRange) {
      dispatch(
        setPixelsPerMinute(
          ganttChartService.getPixelsPerMinute(timelineRange.hoursInView)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineArrayOfTimeString]);
  return (
    <g>
      {timelineRange && pixelsPerHour && (
        <foreignObject
          x="0"
          y={0}
          width={ganttChartVerticalValues.verticalViewHeaderColumn}
          height={timelineRange?.fullDayHours * pixelsPerHour}
        >
          <div
            id="gantt-chart-timeline"
            className={ganttChartStyles.verticalTimelineColumn}
            style={{
              width: ganttChartVerticalValues.verticalViewHeaderColumn,
              position: "absolute",
              top: -pixelsPerHour / 2, //to make timestamp appear at each timeline
            }}
          >
            {pixelPerMinute &&
              timelineArrayOfTimeString &&
              timelineArrayOfTimeString.map((timePoint, index) => (
                <p
                  className="flex items-center justify-start pl-3"
                  style={{ height: pixelsPerHour }}
                  key={index}
                >
                  {index !== 0 && (
                    <span>
                      {timestampService.returnTwoDigitTimeString(
                        timePoint.hour
                      )}
                      :
                      {timestampService.returnTwoDigitTimeString(
                        timePoint.minutes
                      )}
                    </span>
                  )}
                </p>
              ))}
          </div>
        </foreignObject>
      )}
      {pixelsPerHour &&
        timelineArrayOfTimeString &&
        timelineArrayOfTimeString.map((_, index) => (
          <line
            key={index + 1}
            x1={ganttChartVerticalValues.verticalViewHeaderColumn - 20}
            y1={(index + 1) * pixelsPerHour}
            x2={verticalSvgWidth}
            y2={(index + 1) * pixelsPerHour}
            stroke="var(--col-grey)"
            strokeWidth="1"
          />
        ))}
    </g>
  );
};

export default VerticalGanttChartTimelineColumn;
