import { useEffect, useRef, useState } from "react";
import kpiStyles from "../../../styles/UI/kpiPage.module.css";
import { ScaleColor } from "../../kpi/CitizenServiceCard";

const KPIScaleWithData = ({
  title,
  value,
  percent,
  color,
  children,
}: {
  title: string;
  value: string | number;
  percent: number;
  color: ScaleColor;
  children?: React.ReactNode;
}) => {
  const scaleWrapper = useRef<HTMLDivElement>(null);
  const [leftPosition, setLeftPosition] = useState<number>();

  useEffect(() => {
    if (scaleWrapper.current) {
      const width = scaleWrapper.current.getBoundingClientRect().width;
      // the 22 accounts for padding and border
      setLeftPosition((percent / 100) * (width - 22));
    }
  }, [scaleWrapper.current]);
  return (
    <div className={kpiStyles.scaleFullLine}>
      <p className={`${kpiStyles.scaleText} ${kpiStyles.title}`}>{title}</p>
      <div className={kpiStyles.scaleWrapper} ref={scaleWrapper}>
        <div
          style={{
            backgroundColor: color.backgroundColor,
            border: `4px solid ${color.borderColor}`,
          }}
          className={kpiStyles.scale}
        ></div>
        {scaleWrapper.current && (
          <div
            className={kpiStyles.scaleTip}
            style={{
              border: `4px solid ${color.borderColor}`,
              // 6 to account for padding on 0
              left: `calc(${leftPosition === 0 ? "6" : leftPosition}px)`,
            }}
          ></div>
        )}
      </div>
      <div className="flex gap-spacing-md w-[20%] justify-end">
        <p className={`${kpiStyles.scaleText} ${kpiStyles.value}`}>{value}</p>
        {children}
      </div>
    </div>
  );
};

export default KPIScaleWithData;
