import textStyles from "../../../styles/UI/textComponents.module.css";

type GreyTextSize = "SMALL" | "MEDIUM" | "LARGE";

const GreyOpacityText = ({
  children,
  size,
  classes,
}: {
  children: React.ReactNode;
  size: GreyTextSize;
  classes?: string;
}) => {
  const getFontSize = () => {
    switch (size) {
      case "SMALL":
        return "var(--text-sm)";
      case "MEDIUM":
        return "var(--text-md)";
      case "LARGE":
        return "var(--text-lg)";
      default:
        return "var(--text-lg)";
    }
  };

  return (
    <p
      className={`${textStyles.greyOpacityText} ${classes && classes}`}
      style={{ fontSize: getFontSize() }}
    >
      {children}
    </p>
  );
};

export default GreyOpacityText;
