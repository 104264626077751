import teamGroupStyles from "../../styles/ganttChart/teamgroups.module.css";
import { TeamGroupColorMaps } from "../../utils/TeamColorGroupUtils";

const EmployeeTeamsComponent = ({
  teamGroupColorMaps,
  columnWidth,
  leftOffset,
}: {
  teamGroupColorMaps: TeamGroupColorMaps;
  columnWidth: number;
  leftOffset: number;
}) => {
  const { colorGroupCounts, colorGroupToTeamNumberMap } = teamGroupColorMaps;

  return (
    <div
      className={teamGroupStyles.teamgroupContainer}
      style={{ paddingLeft: leftOffset }}
    >
      {Array.from(colorGroupToTeamNumberMap.entries()).map(
        ([colorGroup, teamName]) => (
          <div
            key={teamName}
            className={teamGroupStyles.teamgroupTeamWrapper}
            style={{
              width: colorGroupCounts[colorGroup] * columnWidth,
            }}
          >
            <p className={teamGroupStyles.teamgroupTeamTitle}>
              {colorGroupToTeamNumberMap.get(colorGroup)}
            </p>
            <div className={teamGroupStyles.teamgroupTeamBar} />
          </div>
        ),
      )}
    </div>
  );
};

export default EmployeeTeamsComponent;
