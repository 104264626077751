import overlayStyles from "../../styles/UI/overlay.module.css"

const Overlay = ({
  children,
  clickAway,
  darkBackground
}: {
  children: React.ReactNode | React.ReactNode[];
  clickAway: ()=>void
  darkBackground?: boolean
}) => {
  return (
    <section
      className={overlayStyles.overlay}
      style={{
        backgroundColor: darkBackground ? 'rgba(0,0,0, 0.3)' : 'transparent'
      }}
      onClick={() => {
        clickAway()
      }}
    >
        {children}
    </section>
  );
};

export default Overlay;
