import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActiveNexusFilter } from "../../store/nexusFilters";
import { getPlanningDates } from "../../store/nexusFilters/action";
import Accordion from "../UI/Accordion";
import {
  resetGanttChartErrorMessage,
  setSelectedGanttChartTab,
} from "../../store/ganttChart";
import { ActiveNexusFilter } from "../../types/ganttChart";
import labels from "../../utils/labels";
import { useEffect, useState } from "react";
import allowedPaths from "../../utils/paths";

const HeaderFilters = ({ options }: { options: ActiveNexusFilter[] }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterID = searchParams.get("filterId");
  const filter = searchParams.get("filter");
  const { activeNexusFilters } = useAppSelector(
    (state) => state.nexusFiltersSlice,
  );

  const [defaultIndex, setDefaultIndex] = useState<number | undefined>();

  const handleFilterChange = (filterName: string, value?: string) => {
    dispatch(resetGanttChartErrorMessage());
    setSearchParams((prevParams) => {
      if (value) {
        prevParams.set(filterName, value);
      } else {
        prevParams.delete(filterName);
      }
      return prevParams;
    });
  };

  useEffect(() => {
    if (filterID && activeNexusFilters) {
      const selectedFilter = options.find(
        (option) => option.filterId === parseInt(filterID),
      );
      dispatch(setActiveNexusFilter(selectedFilter));
      setDefaultIndex(
        activeNexusFilters!.findIndex(
          (obj) => obj.filterId === selectedFilter?.filterId,
        ),
      );
    } else if (filter && activeNexusFilters) {
      const selectedFilter = options.find(
        (option) => option.id === parseInt(filter),
      );

      dispatch(setActiveNexusFilter(selectedFilter));
      setDefaultIndex(
        activeNexusFilters!.findIndex((obj) => obj.id === selectedFilter?.id),
      );
    }
  }, [activeNexusFilters, filterID, filter]);

  return (
    <Accordion
      key={defaultIndex}
      variant="SECONDARY"
      onChange={(option: ActiveNexusFilter) => {
        dispatch(getPlanningDates(option.id));
        dispatch(setActiveNexusFilter(option));
        handleFilterChange("filter", option.id.toString());
        // We may be in the middle of a Nexus Landing flow, in which case we shouldn't trigger a global navigation
        if (location.pathname.includes(allowedPaths.nexusLanding)) return;

        // If not, we should default to an active tab
        if (location.pathname !== allowedPaths.ganttChart) {
          dispatch(setSelectedGanttChartTab("Original"));
          navigate({
            pathname: `${allowedPaths.ganttChart}`,
            search: location.search,
          });
        }
      }}
      options={activeNexusFilters!}
      labelKey="filter"
      defaultText={labels.filter}
      displayOption={defaultIndex}
    />
  );
};

export default HeaderFilters;
