// Key management utilities
import { SolutionType, SolutionTypes } from "./index";
import { PlanViewKey } from "./hooks";

export const createPlanViewStateKey = (
  solutionType: SolutionType,
  filterId: number,
  date: string,
): PlanViewKey => ({
  solutionType,
  filterId,
  date,
});

export const serializeKey = (key: PlanViewKey): string =>
  `${key.solutionType}_${key.filterId}_${key.date}`;

export const deserializeKey = (serialized: string): PlanViewKey => {
  const [solutionType, filterId, date] = serialized.split("_");
  if (!Object.values(SolutionTypes).includes(solutionType as SolutionType)) {
    throw new Error(`Invalid solution type: ${solutionType}`);
  }
  return {
    solutionType: solutionType as SolutionType,
    filterId: Number(filterId),
    date,
  };
};
