import { FullTimelineRange } from "../types/timeline";
const { DateTime } = require("luxon");

export type TimelineData = {
  hour: number;
  minutes: number;
  isoString: string;
};

class dateUtil {
  // needs to take date as props.
  static generateTimelineData(
    timelineRange: FullTimelineRange
  ): TimelineData[] {
    // Initialize an array to store the result
    let timelineData = [];

    // Tanja todo: this should already happen when the startTime is dispatched to the store.
    let startTime = DateTime.fromISO(timelineRange.firstHourInFullDay, {
      zone: "Europe/Copenhagen",
    }).startOf("hour");

    for (let i = 0; i < Math.floor(timelineRange.fullDayHours); i++) {
      // Create point on timeline for each hour, following the first hour of the current plan.
      timelineData.push({
        hour: startTime.hour,
        minutes: startTime.minute,
        isoString: startTime.toISO(),
      });

      const oneHourLater = startTime
        .plus({ hours: 1 })
        .setZone("Europe/Copenhagen");

      // Check if we are running into daylight saving time.
      if (oneHourLater.hour - startTime.hour === 2) {
        // Create isoString to add correct object manually.
        const date = new Date(startTime.toISO());
        date.setHours(date.getHours() + 1);
        const newIsoString = date.toISOString();

        timelineData.push({
          hour: startTime.hour + 1,
          minutes: 0,
          isoString: newIsoString,
        });
        startTime = startTime.plus({ hours: 1 }).setZone("Europe/Copenhagen");
      } else {
        startTime = startTime.plus({ hours: 1 }).setZone("Europe/Copenhagen");
      }
    }

    return timelineData;
  }

  static getCalendarValueString = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  static getCalendarDate(date: Date) {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("da-DK", options);
  }
}

export default dateUtil;
