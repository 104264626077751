import React, { createContext, useEffect, useRef, useState } from "react";
import { Stomp, IFrame, CompatClient } from "@stomp/stompjs";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import SockJS from "sockjs-client";
import { AuthService } from "../service/api";
import { handleWebsocketMessage } from "../store/realTimeUpdates";
import { jwtDecode } from "jwt-decode";
import { WebsocketReactivityService } from "../service/WebsocketReactivityService";
// Messaging stuff

export type NexusGanttChartDataReadyResponse = {
  idActiveNexusFilter: number;
  idPlanningDate: number;
  idScenario: number;
};

export type Job = {
  id: number;
  jobRef: string;
  createdDateTime: string;
  updatedDateTime: string;
  completedDateTime: string;
  jobStatus: string;
  idResponsibleUser: number;
  idScenario: number;
  outdatedDateTime: string | null;
  sentToNexusDateTime: string | null;
};

export type MessageType =
  | "NexusLandingProgressOptimizationInitiated"
  | "NexusLandingProgressOptimizationAborted"
  | "NexusLandingProgressOptimizationFailed"
  | "NexusLandingProgressOptimizationFinished"
  | "NexusLandingDataRetrievalAlreadyRunningMessage"
  | "NexusLandingProgressDataRetrievalInitiatedMessage"
  | "NexusLandingProgressNexusDataReadyMessage"
  | "NexusLandingProgressDataRetrievalFailedMessage"
  | "OptimizationStoppedToastMessage";

export type MessageEnvelope<T> = {
  bridgeMessageType: MessageType;
  jobId: string;
  data: T;
};

export type NexusLandingProgressOptimizationInitiatedMessage =
  MessageEnvelope<Job> & {
    bridgeMessageType: "NexusLandingProgressOptimizationInitiated";
  };

export type NexusLandingProgressOptimizationFailedMessage =
  MessageEnvelope<Job> & {
    bridgeMessageType: "NexusLandingProgressOptimizationFailed";
  };

export type NexusLandingProgressOptimizationAbortedMessage =
  MessageEnvelope<Job> & {
    bridgeMessageType: "NexusLandingProgressOptimizationAborted";
  };

export type NexusLandingProgressOptimizationFinishedMessage =
  MessageEnvelope<Job> & {
    bridgeMessageType: "NexusLandingProgressOptimizationFinished";
  };

export type NexusLandingProgressNexusDataReadyMessage =
  MessageEnvelope<NexusGanttChartDataReadyResponse> & {
    bridgeMessageType: "NexusLandingProgressNexusDataReadyMessage";
  };

export type NexusLandingDataRetrievalAlreadyRunningMessage =
  MessageEnvelope<void> & {
    bridgeMessageType: "NexusLandingDataRetrievalAlreadyRunningMessage";
  };

export type NexusLandingProgressDataRetrievalInitiatedMessage =
  MessageEnvelope<void> & {
    bridgeMessageType: "NexusLandingProgressDataRetrievalInitiatedMessage";
  };

export type NexusLandingProgressDataRetrievalFailedMessage =
  MessageEnvelope<string> & {
    bridgeMessageType: "NexusLandingProgressDataRetrievalFailedMessage";
  };

export type OptimizationStoppedToastMessage = MessageEnvelope<string> & {
  bridgeMessageType: "OptimizationStoppedToastMessage";
};

export type WebSocketMessagePayload =
  | NexusLandingProgressOptimizationInitiatedMessage
  | NexusLandingProgressOptimizationFailedMessage
  | NexusLandingProgressOptimizationAbortedMessage
  | NexusLandingProgressOptimizationFinishedMessage
  | NexusLandingProgressNexusDataReadyMessage
  | NexusLandingDataRetrievalAlreadyRunningMessage
  | NexusLandingProgressDataRetrievalInitiatedMessage
  | NexusLandingProgressDataRetrievalFailedMessage
  | OptimizationStoppedToastMessage;

// Context provider

export type WebSocketContextType = {
  client: CompatClient | null;
  readyState: number;
};

const WebSocketContext = createContext<WebSocketContextType>({
  client: null,
  readyState: WebSocket.CLOSED,
});

const HEARTBEAT_INTERVAL = 30000; // 30 seconds
const RECONNECT_DELAY = 2000; // 2 seconds
const MAX_RECONNECT_ATTEMPTS = 5;

interface Token {
  exp: number; // Expiration timestamp
  // Add other token fields as needed
}

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const websocketReactivityService = new WebsocketReactivityService(dispatch);
  const tokenIsReady = useAppSelector(
    (state) => state.apiConfigurationState.interceptorIsReady,
  );

  const clientRef = useRef<CompatClient | null>(null);
  const reconnectAttemptsRef = useRef(0);
  const heartbeatIntervalRef = useRef<NodeJS.Timeout>();
  const [connectionState, setConnectionState] = useState<
    WebSocket["readyState"]
  >(WebSocket.CLOSED);

  const isTokenExpired = (token: string): boolean => {
    try {
      const decodedToken = jwtDecode<Token>(token);
      const currentTime = Math.floor(Date.now() / 1000);

      // Add a buffer of 60 seconds to prevent edge cases
      return decodedToken.exp <= currentTime + 60;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true;
    }
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) return null;

    try {
      const tokens = await AuthService.refreshConcreteToken(refreshToken);
      if (tokens) {
        localStorage.setItem("access_token", tokens.accessToken);
        localStorage.setItem("refresh_token", tokens.refreshToken);
        return tokens.accessToken;
      }
    } catch (error) {
      console.error("Token refresh failed:", error);
      AuthService.redirectToLogin();
    }
    return null;
  };

  const handleDisconnect = async () => {
    setConnectionState(WebSocket.CLOSED);

    if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
      reconnectAttemptsRef.current += 1;
      console.log(
        `Attempting to reconnect (${reconnectAttemptsRef.current}/${MAX_RECONNECT_ATTEMPTS})...`,
      );

      // Try to refresh token before reconnecting
      const currentToken = localStorage.getItem("access_token");
      let tokenToUse = currentToken;

      if (!currentToken || isTokenExpired(currentToken)) {
        tokenToUse = await refreshToken();
      }

      if (tokenToUse) {
        setTimeout(() => connect(), RECONNECT_DELAY);
      }
    } else {
      console.error("Max reconnection attempts reached");
      AuthService.redirectToLogin();
    }
  };

  const connect = (): (() => void) => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) return () => {};

    const wsRoot = window._env_.REACT_APP_WS_URL;
    const socket = new SockJS(wsRoot);
    const client = Stomp.over(socket);

    if (process.env.NODE_ENV === "production") {
      client.debug = () => {};
    }

    client.heartbeatIncoming = HEARTBEAT_INTERVAL;
    client.heartbeatOutgoing = HEARTBEAT_INTERVAL;

    client.connect(
      { Authorization: `Bearer ${accessToken}` },
      () => {
        clientRef.current = client;
        setConnectionState(WebSocket.OPEN);
        reconnectAttemptsRef.current = 0; // Reset reconnect attempts on successful connection

        // Subscribe to messages
        client.subscribe("/bridge", (message) => {
          const payload = JSON.parse(message.body) as WebSocketMessagePayload;
          dispatch(handleWebsocketMessage(payload));
          websocketReactivityService.handleMessage(payload);
        });

        // Set up token refresh check on heartbeat
        heartbeatIntervalRef.current = setInterval(async () => {
          const currentToken = localStorage.getItem("access_token");
          if (currentToken && isTokenExpired(currentToken)) {
            const newToken = await refreshToken();
            if (newToken && client.connected) {
              // Reconnect with new token
              client.disconnect(() => {
                connect();
              });
            }
          }
        }, HEARTBEAT_INTERVAL);
      },
      (error: IFrame) => {
        console.error("STOMP error:", error);
        handleDisconnect();
      },
    );

    // Add event listener for WebSocket connection losses
    socket.onclose = () => {
      handleDisconnect();
    };

    return () => {
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
      }
      if (client.connected) {
        client.disconnect();
      }
      clientRef.current = null;
      setConnectionState(WebSocket.CLOSED);
    };
  };

  useEffect(() => {
    if (!tokenIsReady) return;

    const cleanup = connect();
    return () => {
      cleanup();
    };
  }, [tokenIsReady]);

  return (
    <WebSocketContext.Provider
      value={{
        client: clientRef.current,
        readyState: connectionState,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
