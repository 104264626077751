import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";
import { PlanViewStateEntry } from "../realTimeUpdates";

enum actionTypes {
  retrieveKPIs = "retrieveKPIs",
}

export const getKPICollection = createAsyncThunk(
  actionTypes.retrieveKPIs,
  async (params: { idScenario?: number | undefined }) => {
    const response = await api.get<any>(
      `${API_PATHS.retrieveKPIs}?idScenario=${params.idScenario}`,
    );
    return response.data;
  },
);
